export const formTypes = {
  GENDER: 'GENDER',
  BIRTHDAY: 'BIRTHDAY',
  PHONE: 'PHONE',
  INTENT: 'INTENT',
  NAME: 'NAME',
  ONBOARDING_EXPRESS: 'ONBOARDING_EXPRESS'
}

export const OnBoardingSteps = {
  FULL_NAME_STEP: 'FULL_NAME_STEP',
  GENDER_STEP: 'GENDER_STEP',
  BIRTHDAY_STEP: 'BIRTHDAY_STEP',
  PHONE_STEP: 'PHONE_STEP',
  INTENT_STEP: 'INTENT_STEP',
  ONBOARDING_EXPRESS: 'ONBOARDING_EXPRESS'
}

export const slider = ($translations, img) => ([
  {
    img: img.WelcomeSelia,
    title: $translations['on-boarding'].slider['slide-1'].title,
    text: $translations['on-boarding'].slider['slide-1'].text,
    background: 'gradient',
    button: {
      text: $translations['on-boarding'].buttons.continue,
      type: 'outline'
    }
  },
  {
    img: img.FindSpecialist,
    title: $translations['on-boarding'].slider['slide-2'].title,
    text: $translations['on-boarding'].slider['slide-2'].text,
    background: 'gradient',
    button: {
      text: $translations['on-boarding'].buttons.continue,
      type: 'outline'
    }
  },
  {
    img: img.Start,
    title: $translations['on-boarding'].slider['slide-3'].title,
    text: $translations['on-boarding'].slider['slide-3'].text,
    background: 'gradient-alt',
    button: {
      text: $translations['on-boarding'].buttons.start,
      type: 'block'
    }
  }
])

export const backScreen = ($translations, img) => ([{
  img: img.Start,
  title: $translations['on-boarding'].slider.extra.title,
  text: $translations['on-boarding'].slider.extra.text,
  background: 'gradient-alt',
  button: {
    text: $translations['on-boarding'].buttons['continue-questionnaire'],
    type: 'block'
  }
}])
