<template>
  <SliderCarrousel
    :slides="slides"
    :totalSteps="totalSteps"
    localStorageKey="ShowObSlider"
    :callback="goQuestionnaire"
    :callbackSegment="handlerEvents"
  />
</template>
<script>
import { PNG } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { backScreen } from './configuration/configuration'
import { activeStatus } from '@/types/state'
import SliderCarrousel from '@/components/misc/SliderCarrousel.vue'
import { OnboardingEvent } from '@/types/events'
export default {
  name: 'ObSlider',
  components: {
    SliderCarrousel
  },
  data () {
    return {
      slides: backScreen(this.$translations, PNG),
      totalSteps: 1,
      isDone: JSON.parse(localStorage.getItem('ShowObSlider'))
    }
  },
  mounted () {
    if (activeStatus.includes(this.user.status)) {
      this.$router.replace({ name: 'Home' })
    }
  },
  methods: {
    goQuestionnaire () {
      localStorage.removeItem('ShowObSlider')
      if (!this.user.first_name || !this.user.last_name) {
        this.$router.push({
          name: 'QuestionnaireOnboarding',
          params: { status: this.user.status, advanced: true }
        })
      } else {
        this.$router.push({
          name: 'QuestionnaireOnboarding',
          params: { status: this.user.status }
        })
      }
    },
    handlerEvents () {
      const body = {
        user: { ...this.user },
        plaftorm: this.$store.getters.platform,
        version: this.$version
      }
      OnboardingEvent.onboadingScreenBack(body)
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
