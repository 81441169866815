<template>
  <div class="slider-carrousel" v-if="slides" :class="slideCurrent.background">
    <!-- close button -->
    <div class="max-width" v-if="close">
      <div class="icon-display icon-close cursor-pointer" @click="close"/>
    </div>

    <div class="slider-carrousel-content">
      <!-- content -->
      <div class="slider-carrousel-content-body">
        <div class="max-width">
          <div ref="body" class="slider-carrousel-body fade-in-left">
            <img class="img" :src="slideCurrent.img" :alt="slideCurrent.text" />
            <div class="heading-big">
              {{ slideCurrent.title }}
            </div>
            <div class="body" :class="{ 'margin-bottom-lg': isDone }">
              {{ slideCurrent.text }}
            </div>

            <!-- choices -->
            <div class="margin-top choices" v-if="slideCurrent.options">
              <MediumCard
                v-for="(option, index) in slideCurrent.options" :key="index"
                :illustration="option.img"
                :title="option.title"
                :bottomLine="option.text"
                :showAction="option.showAction"
                @clicked="option.action"
              />
            </div>
          </div>

          <div
            class="slider-carrousel-stepper"
            v-if="totalSteps > 1 && !slideCurrent.options"
          >
            <div
              class="slider-carrousel-stepper-item"
              :class="{ active: i === current }"
              v-for="(v, i) of totalSteps"
              @click="handlerAction(i)"
              :key="i"
            />
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="slider-carrousel-content-footer">
        <slot name="content"/>

        <Button
          v-if="slideCurrent.buttonText && slideCurrent.buttonType"
          id="slider-button"
          qa="slider_button"
          :text="slideCurrent.buttonText"
          :type="slideCurrent.buttonType"
          @clicked="handlerAction"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button, MediumCard } from '@seliaco/red-panda'

export default {
  name: 'SliderCarrousel',
  components: { Button, MediumCard },
  props: {
    slides: Array,
    totalSteps: Number,
    localStorageKey: String,
    callback: Function,
    callbackSegment: Function,
    close: Function
  },
  data () {
    return {
      current: 0,
      isDone: false
    }
  },
  mounted () {
    this.isDone = JSON.parse(localStorage.getItem(this.localStorageKey))
  },
  methods: {
    handlerAction (val = this.current + 1) {
      const button = document.getElementById('slider-button')
      button.blur()

      this.callbackSegment && this.callbackSegment(val)

      if (this.isDone) {
        this.callback()
      } else {
        if (val === this.totalSteps) {
          localStorage.setItem(this.localStorageKey, JSON.stringify(true))
          this.callback()
        } else {
          this.$refs.body.classList = 'slider-carrousel-body fade-out-right'
          setTimeout(() => {
            this.$refs.body.classList = 'slider-carrousel-body fade-in-left'
            this.current = val
          }, 500)
        }
      }
    }
  },
  computed: {
    slideCurrent () {
      const slide = this.slides[this.current]

      return {
        img: slide.img,
        title: slide.title,
        text: slide.text,
        background: slide.background,
        buttonText: slide.button?.text,
        buttonType: slide.button?.type,
        options: slide.options
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.slider-carrousel
  width: 100%
  height: 100vh

  &.gradient
    background: var(--gradient-one)
    transition: .5s
  &.gradient-alt
    background: var(--gradient-two)
    transition: .5s

  .choices
    width: 100%
    .medium-card
      max-width: 480px

  .icon-close
    position: absolute
    top: 10px
    right: 10px

  &-content
    min-height: 100%
    overflow-y: hidden
    overflow-x: hidden
    display: grid
    grid-template-rows: auto min-content
    padding: 16px 16px calc(#{env(safe-area-inset-bottom)} + 16px)

    &-footer
      display: flex
      flex-direction: column
      justify-content: flex-start
      width: 480px
      margin: 0 auto 100px

    .max-width
      position: absolute
      max-width: 480px
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      display: initial
      width: 100%
      padding: 16px

  &-body
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    position: relative
    transition: .5s

    img
      width: 296px
      height: 296px
      margin-bottom: 32px
      object-fit: contain

    .body
      width: 100%
      margin-top: 16px

  &-stepper
    display: flex
    gap: 8px
    justify-content: center
    align-items: center
    margin-top: 32px

    &-item
      border-radius: 50%
      background: var(--purple-40)
      width: 10px
      height: 10px
      cursor: pointer
      &.active
        background: var(--purple)

.fade-in-left
  animation: fadeInLeft .51s ease-in-out

.fade-out-right
  animation: fadeOutRight .51s ease-in-out

@media (max-height: 850px)
  .slider-carrousel
    &-content
      &-footer
        margin-bottom: 0

@media (max-width: 514px)
  .slider-carrousel
    &-content
      &-footer
        width: unset
        margin: 0

@keyframes fadeInLeft
  0%
    left: 430px
    opacity: 0
  100%
    left: 0
    opacity: 1

@keyframes fadeOutRight
  0%
    left: 0
    opacity: 1
  100%
    left: -430px
    opacity: 0

</style>
